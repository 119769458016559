import { Component } from '@angular/core';
import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent() {
      return import('./components/home/home.component').then(m => m.HomeComponent);
    }
  },
  {
    path: 'work/:id',
    loadComponent() {
      return import('./components/work/work-details/work-details.component').then(m => m.WorkDetailsComponent);
    }
  }
];
