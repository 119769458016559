import { Component } from '@angular/core';

@Component({
  selector: 'toro-language-banner',
  standalone: true,
  imports: [],
  templateUrl: './language-banner.component.html',
  styleUrl: './language-banner.component.scss'
})
export class LanguageBannerComponent {

}
